import { ChangeEvent } from 'react'
import StickyNoteIcon from '@mui/icons-material/StickyNote2Outlined'
import SyncIcon from '@mui/icons-material/Sync'
import { Button, Stack, SxProps, Tooltip } from '@mui/material'
import { useIsMutating } from '@tanstack/react-query'

import useEditOrder from 'api/order/useEditOrder'
import useOrder from 'api/order/useOrder'
import useUpdateCustomerInfo from 'api/order/useUpdateCustomerInfo'
import { RelativeBackdrop } from 'components/Backdrop'
import { FieldsGroup } from 'components/FormHelpers'
import { LegacyTerms } from 'components/LegacyTerms'
import { ReadonlyField } from 'components/ReadonlyField'
import { Link } from 'components/StyledLink'
import { Switch } from 'components/Switch'
import Warning from 'components/WarningMessage'
import { getFullAddressContent } from 'utils/address'
import { Order } from 'utils/global-types'
import { formatGramsToKg } from 'utils/helpers'
import { getExcessWeightWarningMessage } from 'utils/order'
import { formatPrice } from 'utils/price'

import QuoteDetailsSection from './QuoteDetailsSection'

const iconRotation: SxProps = {
  animation: 'spin 1s linear infinite',
}

type QuoteDetailsMainProps = {
  id: Order['order_id']
}

export default function QuoteDetailsGeneral({ id }: QuoteDetailsMainProps) {
  const { data } = useOrder(id, 'quote')
  const { order: quote, shippingMethod } = data!

  const { mutate: editQuote } = useEditOrder(id, 'quote')
  const { mutate: updateCustomerInfo, isLoading: isCustomerUpdates } =
    useUpdateCustomerInfo(id)
  const isLoading = useIsMutating({ mutationKey: ['order', id] }) > 0

  const billingAddress = getFullAddressContent({
    address_line_1: quote.billing_address_line_1,
    address_line_2: quote.billing_address_line_2,
    city: quote.billing_city,
    postal_code: quote.billing_postal_code,
    country: quote.billing_country,
    organization_name: quote.billing_organization_name,
  })

  const shippingAddress = getFullAddressContent({
    address_line_1: quote.shipping_address_line_1,
    address_line_2: quote.shipping_address_line_2,
    city: quote.shipping_city,
    postal_code: quote.shipping_postal_code,
    country: quote.shipping_country,
    organization_name: quote.shipping_organization_name,
  })

  const deleteLegacyTerms = () => {
    editQuote({ terms_notes: '' })
  }

  const toggleChargeVAT = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    editQuote({ is_charge_vat_overwrite: checked })
  }

  const handleUpdateQuote = () => {
    updateCustomerInfo()
  }

  const calculatedShippingFee = quote.shipping_fee
    ? formatPrice(quote.shipping_fee, quote.currency)
    : '-'

  const alternativeShippingFee = quote.shipping_fee_overwrite
    ? formatPrice(quote.shipping_fee_overwrite, quote.currency)
    : '-'

  const warningMessage = getExcessWeightWarningMessage(quote.weight_net)
  const isChargeVAT = quote.is_charge_vat_overwrite ?? quote.is_charge_vat

  return (
    <Stack
      direction="row"
      columnGap={4}
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <RelativeBackdrop open={isLoading} />
      {!quote.is_latest_updates && (
        <Stack flexBasis="100%" direction="row" justifyContent="end">
          <Button
            variant="outlined"
            disabled={isCustomerUpdates}
            onClick={handleUpdateQuote}
            startIcon={
              <SyncIcon sx={isCustomerUpdates ? iconRotation : undefined} />
            }
          >
            Latest updates
          </Button>
        </Stack>
      )}
      <QuoteDetailsSection title="Customer details" flex="1">
        <ReadonlyField label="Name">
          <Stack direction="row" gap={1}>
            <Link to={`/customers/${quote.customer}`}>
              {quote.customer_name}
            </Link>
            {quote.customer_notes && (
              <Tooltip title={quote.customer_notes}>
                <StickyNoteIcon color="info" />
              </Tooltip>
            )}
          </Stack>
        </ReadonlyField>
        <ReadonlyField label="Email">{quote.customer_email}</ReadonlyField>
        <ReadonlyField label="Organization">
          <Stack direction="row" gap={1}>
            <Link to={`/organizations/${quote.organization.uuid}`}>
              {quote.organization.name}
            </Link>
            {quote.organization.notes && (
              <Tooltip title={quote.organization.notes}>
                <StickyNoteIcon color="info" />
              </Tooltip>
            )}
          </Stack>
        </ReadonlyField>
        <ReadonlyField label="Phone number">
          {quote.customer_phone_number}
        </ReadonlyField>
        <ReadonlyField label="Quote REF">{quote.rfq_id}</ReadonlyField>
        <ReadonlyField label="Quote Note">{quote.notes}</ReadonlyField>
      </QuoteDetailsSection>

      <Stack justifyContent="start" flex="1">
        <QuoteDetailsSection title="Billing">
          <ReadonlyField label="Billing address">
            {billingAddress}
          </ReadonlyField>
        </QuoteDetailsSection>
        <QuoteDetailsSection title="Payment terms">
          <FieldsGroup sameWidth>
            <ReadonlyField label="VAT number">{quote.vat_number}</ReadonlyField>
            <Switch
              label="Charge VAT"
              sx={{ flex: 1, mt: -0.75 }}
              defaultChecked={isChargeVAT}
              onChange={toggleChargeVAT}
            />
          </FieldsGroup>
          <FieldsGroup sameWidth>
            <ReadonlyField label="Advance %">
              {quote.advance_limit}
            </ReadonlyField>
            <ReadonlyField label="Upon ship. %">
              {quote.upon_shipping_limit}
            </ReadonlyField>
            <ReadonlyField label="NET %">{quote.net_limit}</ReadonlyField>
            <ReadonlyField label="NET days">
              {quote.max_outstanding_days}
            </ReadonlyField>
          </FieldsGroup>
        </QuoteDetailsSection>
        {quote.terms_notes && (
          <LegacyTerms terms={quote.terms_notes} onDelete={deleteLegacyTerms} />
        )}
      </Stack>

      <QuoteDetailsSection title="Shipping" flex="1">
        <ReadonlyField label="Shipping address">
          {shippingAddress}
        </ReadonlyField>
        <FieldsGroup sameWidth>
          {quote.shipping_attn && (
            <ReadonlyField label="ATTN">{quote.shipping_attn}</ReadonlyField>
          )}
          <ReadonlyField label="Shipping instructions">
            {quote.shipping_notes}
          </ReadonlyField>
        </FieldsGroup>
        <FieldsGroup sameWidth>
          <ReadonlyField label="Shipping method">
            {shippingMethod?.name}
          </ReadonlyField>
          <ReadonlyField label="Total weight (kg)">
            {formatGramsToKg(quote.weight_net)}
          </ReadonlyField>
        </FieldsGroup>
        <Warning>{warningMessage}</Warning>
        <FieldsGroup sameWidth>
          <ReadonlyField label="Calculated DAP/DHL">
            {calculatedShippingFee}
          </ReadonlyField>
          <ReadonlyField label="Alternative ship. fee">
            {alternativeShippingFee}
          </ReadonlyField>
        </FieldsGroup>
        <FieldsGroup sameWidth>
          <ReadonlyField label="Handling fee">
            {formatPrice(quote.handling_fee_relevant_display, quote.currency)}
          </ReadonlyField>
        </FieldsGroup>
      </QuoteDetailsSection>
    </Stack>
  )
}
