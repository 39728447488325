import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import StickyNoteIcon from '@mui/icons-material/StickyNote2Outlined'
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import useEditOrder from 'api/order/useEditOrder'
import useOrder from 'api/order/useOrder'
import useShippingMethod from 'api/shipping/useShippingMethod'
import { CalculatedShippingFee } from 'components/CalculatedShippingFee'
import { Autocomplete, Select, Switch } from 'components/Form'
import Checkbox from 'components/Form/Checkbox'
import ControlledInput from 'components/Form/ControlledInput'
import { FieldsGroup } from 'components/FormHelpers'
import { ReadonlyField } from 'components/ReadonlyField'
import { DataStatsBar, DataStatsBarItemI } from 'components/StatsBar'
import { Link } from 'components/StyledLink'
import Warning from 'components/WarningMessage'
import { useAbility } from 'context/Ability'
import {
  getFullAddressString,
  mapAddressOption,
  mapShippingMethodOption,
} from 'utils/address'
import { mapCustomerOptions } from 'utils/customer'
import { AutocompleteOption, Quote } from 'utils/global-types'
import { formatGramsToKg } from 'utils/helpers'
import { getExcessWeightWarningMessage } from 'utils/order'
import {
  formatPrice,
  percentageInputOptions,
  priceInputOptions,
} from 'utils/price'

import { QuoteFormI } from './QuoteForm'
import TermsHeader from './TermsHeader'

const customerStats: DataStatsBarItemI<Partial<Quote>>[] = [
  {
    label: 'Name',
    id: 'customer_name',
    value: (quote) => (
      <Stack direction="row" gap={1}>
        <Link to={`/customers/${quote?.customer}`}>{quote?.customer_name}</Link>
        {quote.customer_notes && (
          <Tooltip title={quote.customer_notes}>
            <StickyNoteIcon color="info" />
          </Tooltip>
        )}
      </Stack>
    ),
  },
  {
    label: 'Email',
    id: 'customer_email',
  },
  {
    label: 'Organization',
    id: 'organization.name',
    value: (quote) => (
      <Stack direction="row" gap={1}>
        <Link to={`/organizations/${quote?.organization?.uuid}`}>
          {quote?.organization?.name}
        </Link>
        {quote.organization?.notes && (
          <Tooltip title={quote.organization?.notes}>
            <StickyNoteIcon color="info" />
          </Tooltip>
        )}
      </Stack>
    ),
  },
  {
    label: 'Phone number',
    id: 'customer_phone_number',
  },
]

const customerStatsBarStyles = {
  mb: 2,
  borderRadius: 2,
  padding: '16px 28px',
  bgcolor: 'action.hover',
  '& .MuiBox-root p:last-child': {
    mt: 0.625,
  },
  '& .MuiTextField-root': {
    mb: 0,
  },
}

export default function QuoteFormFields({ isVisible }: { isVisible: boolean }) {
  const { id } = useParams()
  const ability = useAbility()

  const { setValue, watch } = useFormContext<QuoteFormI>()
  const [customerUUID, sameBillingAddress, shippingMethodUUID] = watch([
    'quote.customer',
    'quote.same_billing_address',
    'quote.shipping_method',
  ])

  const { mutateAsync: editQuote } = useEditOrder(id!, 'quote', true)
  const { data: shippingMethod } = useShippingMethod(shippingMethodUUID)
  const { data } = useOrder(id!, 'quote')
  const { order: quote, shippingMethod: savedShippingMethod } = data!
  const {
    customer,
    organization,
    weight_net,
    shipping_fee,
    vat_number,
    currency,
    quote_terms_context,
    payment_terms_context,
    handling_fee_relevant_display,
  } = quote

  const onCustomerChange = (option?: AutocompleteOption | null) => {
    if (option != null) {
      setValue('quote.customer', option.id)

      if (option.id !== customer) {
        editQuote({ customer: option.id })
      }
    }
  }

  const resetCustomer = () => {
    setValue('quote.customer', '')
  }

  useEffect(() => {
    if (shippingMethod?.rate_calculation_type !== 'no-fees') {
      setValue('quote.shipping_instructions', '')
    }
  }, [shippingMethod?.rate_calculation_type])

  const shippingMethodDefaultOption: AutocompleteOption | undefined =
    quote?.shipping_method
      ? {
          id: quote.shipping_method,
          label: quote.shipping_method_name,
        }
      : undefined

  const shippingAddressDefaultOption: AutocompleteOption | undefined =
    quote?.shipping_address
      ? {
          id: quote.shipping_address,
          label: getFullAddressString({
            organization_name: quote.shipping_organization_name,
            address_line_1: quote.shipping_address_line_1,
            address_line_2: quote.shipping_address_line_2,
            city: quote.shipping_city,
            country_name: quote.shipping_country,
          }),
        }
      : undefined

  const billingAddressDefaultOption: AutocompleteOption | undefined =
    quote?.billing_address
      ? {
          id: quote.billing_address,
          label: getFullAddressString({
            organization_name: quote.billing_organization_name,
            address_line_1: quote.billing_address_line_1,
            address_line_2: quote.billing_address_line_2,
            city: quote.billing_city,
            country_name: quote.billing_country,
          }),
        }
      : undefined

  const isCustomerSelected = customerUUID !== ''
  const canChangeTerms = ability.can('change_terms_of', 'customer')
  const termsHelperText = canChangeTerms
    ? undefined
    : `You can't change organization terms`

  const showAccountNumber = shippingMethod?.rate_calculation_type === 'no-fees'
  const excessWeightWarningMessage = getExcessWeightWarningMessage(weight_net)
  const showNoWeightWarning =
    !quote.is_all_products_have_weight &&
    !!savedShippingMethod &&
    savedShippingMethod.rate_calculation_type !== 'no-fees'
  const isChargeVAT = quote.is_charge_vat_overwrite ?? quote.is_charge_vat

  return (
    <Box
      sx={{
        p: 3.5,
        width: '100%',
        display: isVisible ? 'block' : 'none',
      }}
    >
      {isCustomerSelected ? (
        <>
          <DataStatsBar
            items={customerStats}
            sx={customerStatsBarStyles}
            data={quote}
            subtitlesSx={{ color: 'text.secondary' }}
          >
            <Button onClick={resetCustomer}>Change customer</Button>
          </DataStatsBar>
          <ControlledInput name="quote.notes" label="Notes" multiline />
          <Divider sx={{ mb: 4 }} />

          <FieldsGroup>
            <ControlledInput
              name="quote.validity_days"
              label="Validity days"
              type="number"
              inputProps={{ min: 0 }}
            />
            <ControlledInput name="quote.rfq_id" label="Quote REF" />
          </FieldsGroup>
          <Divider sx={{ mb: 4 }} />

          <Typography fontWeight="500" my={2}>
            Shipping
          </Typography>
          <Warning>{excessWeightWarningMessage}</Warning>
          <FieldsGroup sameWidth gap={2}>
            <Autocomplete
              name="quote.shipping_method"
              label="Shipping method"
              url="customers/shipping-methods/"
              enabled
              value={shippingMethodDefaultOption}
              mapOptions={mapShippingMethodOption}
            />
            <ReadonlyField label="Total weight (kg)">
              {formatGramsToKg(weight_net)}
            </ReadonlyField>
            <CalculatedShippingFee
              value={shipping_fee}
              name="quote.shipping_fee_overwrite"
              currency={currency}
            />
            <ControlledInput
              label="Alternative shipping fee"
              name="quote.shipping_fee_overwrite"
              helperText="Field change affects total amount. Leave empty to use calculated fee."
              type="number"
              inputProps={priceInputOptions}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currency.toUpperCase()}
                  </InputAdornment>
                ),
              }}
            />
            <ReadonlyField label="Handling fee">
              {formatPrice(handling_fee_relevant_display, currency)}
            </ReadonlyField>
          </FieldsGroup>
          {showAccountNumber && (
            <ControlledInput
              label="Shipping account number"
              name="quote.shipping_instructions"
              sx={{ width: 'calc(20% - 12.8px)' }}
            />
          )}
          {showNoWeightWarning && (
            <Warning>
              {savedShippingMethod.name_display} is impossible. Not all weights
              are known. Please select EXW delivery method.
            </Warning>
          )}
          <Autocomplete
            name="quote.shipping_address"
            label="Shipping address"
            url="customers/addresses/"
            mapOptions={mapAddressOption}
            enabled
            hideClearIcon
            value={shippingAddressDefaultOption}
            filters={{
              organization: organization?.uuid ?? '',
              type: 'shipping',
            }}
          />
          <FieldsGroup sameWidth>
            <ControlledInput name="quote.shipping_attn" label="ATTN" />
            <ControlledInput
              name="quote.shipping_notes"
              label="Shipping instructions"
            />
          </FieldsGroup>

          <Divider sx={{ mb: 4 }} />

          <Typography fontWeight="500" my={2}>
            Billing
          </Typography>

          <Checkbox
            label="Same shipping and billing address"
            name="quote.same_billing_address"
          />

          {!sameBillingAddress && (
            <Autocomplete
              name="quote.billing_address"
              label="Billing address"
              url="customers/addresses/"
              mapOptions={mapAddressOption}
              hideClearIcon
              enabled
              value={billingAddressDefaultOption}
              filters={{
                organization: organization?.uuid ?? '',
                type: 'billing',
              }}
            />
          )}

          <Typography fontWeight="500" my={2}>
            Payment terms
          </Typography>
          <FieldsGroup sameWidth gap={2}>
            <FieldsGroup sameWidth gap={2}>
              <ReadonlyField label="VAT number">{vat_number}</ReadonlyField>
              <Switch
                label="Charge VAT"
                name="quote.is_charge_vat_overwrite"
                defaultChecked={isChargeVAT}
              />
            </FieldsGroup>
            <Select
              name="quote.currency"
              label="Preferred currency"
              defaultValue={currency || 'eur'}
            >
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="eur">EUR</MenuItem>
              <MenuItem value="cad">CAD</MenuItem>
            </Select>
          </FieldsGroup>
          <FieldsGroup sameWidth gap={2}>
            <ControlledInput
              name="quote.advance_limit"
              label="Advance %"
              type="number"
              inputProps={percentageInputOptions}
            />
            <ControlledInput
              name="quote.upon_shipping_limit"
              label="Upon shipping %"
              type="number"
              inputProps={percentageInputOptions}
            />
            <ControlledInput
              name="quote.net_limit"
              label="NET %"
              type="number"
              inputProps={{ min: 0 }}
              disabled={!canChangeTerms}
              helperText={termsHelperText}
            />
            <ReadonlyField label="NET days">
              {quote.max_outstanding_days}
            </ReadonlyField>
          </FieldsGroup>

          <Divider sx={{ mb: 4 }} />

          <TermsHeader title="Quote terms" context={quote_terms_context} />
          <ControlledInput
            name="quote.quote_terms_template"
            multiline
            label="Quote terms"
          />

          <TermsHeader title="Payment terms" context={payment_terms_context} />
          <ControlledInput
            name="quote.payment_terms_template"
            multiline
            label="Payment terms"
          />
        </>
      ) : (
        <Autocomplete
          name="customer"
          label="Customer"
          url="/customers/customers/"
          placeholder="Find a customer"
          onChange={onCustomerChange}
          mapOptions={mapCustomerOptions}
          InputLabelProps={{ shrink: true }}
          sx={{ pt: 0.5 }}
        />
      )}
    </Box>
  )
}
